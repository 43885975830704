import s from "./contacts.module.scss";
import KgFlag from "../icons/KgFlag";
import UzFlag from "../icons/UzFlag";
import RuFlag from "../icons/RuFlag";
import KzFlag from "../icons/KzFlag";
import mail from "../../Image/mail-fix.jpg";
import React from "react";

const Contacts = () => (
    <div className={s.contacts}>
        <div
            className={s.contacts__links}
        >
            <a
                className={s.contacts__link}
                href="tel:+996997980303"
            >
                <KgFlag/>
                <span>+996997980303</span>
            </a>
            <a
                className={s.contacts__link}
                href="tel:+996553100115"
            >
                <KgFlag/>
                <span>+996553100115</span>
            </a>
            <a
                className={s.contacts__link}
                href="tel:+998911658385"
            >
                <UzFlag/>
                <span>+998911658385</span>
            </a>
            <a
                className={s.contacts__link}
                href="tel:+79688884478"
            >
                <RuFlag/>
                <span>+79688884478</span>
            </a>
            <a
                className={s.contacts__link}
                href="tel:+77053091191"
            >
                <KzFlag/>
                <span>+77053091191</span>
            </a>
        </div>
        <div className={s.contacts__email}>
            <a
                href="mailto:intrenational.media@gmail.com"
                className={`${s.whats__color} ${s.mail__color} ${s.contacts__email_link}`}
            >
                <img src={mail} alt="" className={s.img__contact}/>
                intrenational.media@gmail.com
            </a>
        </div>
    </div>
)

export default Contacts;
