const KzFlag = () => <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <rect x="1" y="4" width="30" height="24" rx="4" ry="4" fill="#4daac1"></rect>
    <path
        d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
        opacity=".15"></path>
    <circle cx="16.008" cy="13.938" r="3.515" fill="#fbec5a"></circle>
    <path id="1705926025362-471600_C"
          d="M16.008,9.943c.209-.003,.287-.133,.287-.29,0-.209-.287-1.204-.287-1.204,0,0-.287,.995-.287,1.204,0,.157,.078,.29,.287,.29Z"
          fill="#fbec5a"></path>
    <path id="1705926025362-471600_C-2"
          d="M20.003,13.938c.003,.209,.133,.287,.29,.287,.209,0,1.204-.287,1.204-.287,0,0-.995-.287-1.204-.287-.157,0-.29,.078-.29,.287Z"
          fill="#fbec5a"></path>
    <path id="1705926025362-471600_C-3"
          d="M16.008,17.933c-.209,.003-.287,.133-.287,.29,0,.209,.287,1.204,.287,1.204,0,0,.287-.995,.287-1.204,0-.157-.078-.29-.287-.29Z"
          fill="#fbec5a"></path>
    <path id="1705926025362-471600_C-4"
          d="M12.014,13.938c-.003-.209-.133-.287-.29-.287-.209,0-1.204,.287-1.204,.287,0,0,.995,.287,1.204,.287,.157,0,.29-.078,.29-.287Z"
          fill="#fbec5a"></path>
    <path id="1705926025362-471600_C-5"
          d="M17.537,10.247c.194,.078,.317-.013,.377-.158,.08-.193,.195-1.223,.195-1.223,0,0-.646,.81-.726,1.003-.06,.145-.039,.298,.155,.378Z"
          fill="#fbec5a"></path>
    <path id="1705926025362-471600_C-6"
          d="M19.699,15.467c-.078,.194,.013,.317,.158,.377,.193,.08,1.223,.195,1.223,.195,0,0-.81-.646-1.003-.726-.145-.06-.298-.039-.378,.155Z"
          fill="#fbec5a"></path>
    <path id="1705926025362-471600_C-7"
          d="M14.48,17.629c-.194-.078-.317,.013-.377,.158-.08,.193-.195,1.223-.195,1.223,0,0,.646-.81,.726-1.003,.06-.145,.039-.298-.155-.378Z"
          fill="#fbec5a"></path>
    <path id="1705926025362-471600_C-8"
          d="M12.318,12.409c.078-.194-.013-.317-.158-.377-.193-.08-1.223-.195-1.223-.195,0,0,.81,.646,1.003,.726,.145,.06,.298,.039,.378-.155Z"
          fill="#fbec5a"></path>
    <path id="1705926025362-471600_C-9"
          d="M18.833,11.113c.15,.146,.297,.109,.408-.002,.148-.148,.648-1.055,.648-1.055,0,0-.907,.501-1.055,.648-.111,.111-.15,.26-.002,.408Z"
          fill="#fbec5a"></path>
    <path id="1705926025362-471600_C-10"
          d="M18.833,16.763c-.146,.15-.109,.297,.002,.408,.148,.148,1.055,.648,1.055,.648,0,0-.501-.907-.648-1.055-.111-.111-.26-.15-.408-.002Z"
          fill="#fbec5a"></path>
    <path id="1705926025362-471600_C-11"
          d="M13.184,16.763c-.15-.146-.297-.109-.408,.002-.148,.148-.648,1.055-.648,1.055,0,0,.907-.501,1.055-.648,.111-.111,.15-.26,.002-.408Z"
          fill="#fbec5a"></path>
    <path id="1705926025362-471600_C-12"
          d="M13.184,11.113c.146-.15,.109-.297-.002-.408-.148-.148-1.055-.648-1.055-.648,0,0,.501,.907,.648,1.055,.111,.111,.26,.15,.408,.002Z"
          fill="#fbec5a"></path>
    <path id="1705926025362-471600_C-13"
          d="M19.699,12.409c.082,.192,.233,.215,.378,.155,.193-.08,1.003-.726,1.003-.726,0,0-1.03,.115-1.223,.195-.145,.06-.238,.183-.158,.377Z"
          fill="#fbec5a"></path>
    <path id="1705926025362-471600_C-14"
          d="M17.537,17.629c-.192,.082-.215,.233-.155,.378,.08,.193,.726,1.003,.726,1.003,0,0-.115-1.03-.195-1.223-.06-.145-.183-.238-.377-.158Z"
          fill="#fbec5a"></path>
    <path id="1705926025362-471600_C-15"
          d="M12.318,15.467c-.082-.192-.233-.215-.378-.155-.193,.08-1.003,.726-1.003,.726,0,0,1.03-.115,1.223-.195,.145-.06,.238-.183,.158-.377Z"
          fill="#fbec5a"></path>
    <path id="1705926025362-471600_C-16"
          d="M14.48,10.247c.192-.082,.215-.233,.155-.378-.08-.193-.726-1.003-.726-1.003,0,0,.115,1.03,.195,1.223,.06,.145,.183,.238,.377,.158Z"
          fill="#fbec5a"></path>
    <path id="1705926025362-471600_C-17"
          d="M16.788,10.02c.206,.038,.308-.075,.338-.228,.041-.205-.047-1.237-.047-1.237,0,0-.476,.92-.517,1.125-.031,.154,.02,.3,.225,.341Z"
          fill="#fbec5a"></path>
    <path id="1705926025362-471600_C-18"
          d="M19.927,14.717c-.038,.206,.075,.308,.228,.338,.205,.041,1.237-.047,1.237-.047,0,0-.92-.476-1.125-.517-.154-.031-.3,.02-.341,.225Z"
          fill="#fbec5a"></path>
    <path id="1705926025362-471600_C-19"
          d="M15.229,17.856c-.206-.038-.308,.075-.338,.228-.041,.205,.047,1.237,.047,1.237,0,0,.476-.92,.517-1.125,.031-.154-.02-.3-.225-.341Z"
          fill="#fbec5a"></path>
    <path id="1705926025362-471600_C-20"
          d="M12.09,13.159c.038-.206-.075-.308-.228-.338-.205-.041-1.237,.047-1.237,.047,0,0,.92,.476,1.125,.517,.154,.031,.3-.02,.341-.225Z"
          fill="#fbec5a"></path>
    <path id="1705926025362-471600_C-21"
          d="M18.228,10.616c.175,.114,.313,.049,.4-.081,.116-.174,.43-1.161,.43-1.161,0,0-.792,.668-.908,.842-.087,.13-.096,.285,.078,.401Z"
          fill="#fbec5a"></path>
    <path id="1705926025362-471600_C-22"
          d="M19.33,16.157c-.114,.175-.049,.313,.081,.4,.174,.116,1.161,.43,1.161,.43,0,0-.668-.792-.842-.908-.13-.087-.285-.096-.401,.078Z"
          fill="#fbec5a"></path>
    <path id="1705926025362-471600_C-23"
          d="M13.789,17.259c-.175-.114-.313-.049-.4,.081-.116,.174-.43,1.161-.43,1.161,0,0,.792-.668,.908-.842,.087-.13,.096-.285-.078-.401Z"
          fill="#fbec5a"></path>
    <path id="1705926025362-471600_C-24"
          d="M12.687,11.718c.114-.175,.049-.313-.081-.4-.174-.116-1.161-.43-1.161-.43,0,0,.668,.792,.842,.908,.13,.087,.285,.096,.401-.078Z"
          fill="#fbec5a"></path>
    <path id="1705926025362-471600_C-25"
          d="M19.33,11.718c.118,.172,.27,.165,.401,.078,.174-.116,.842-.908,.842-.908,0,0-.987,.314-1.161,.43-.13,.087-.198,.226-.081,.4Z"
          fill="#fbec5a"></path>
    <path id="1705926025362-471600_C-26"
          d="M18.228,17.259c-.172,.118-.165,.27-.078,.401,.116,.174,.908,.842,.908,.842,0,0-.314-.987-.43-1.161-.087-.13-.226-.198-.4-.081Z"
          fill="#fbec5a"></path>
    <path id="1705926025362-471600_C-27"
          d="M12.687,16.157c-.118-.172-.27-.165-.401-.078-.174,.116-.842,.908-.842,.908,0,0,.987-.314,1.161-.43,.13-.087,.198-.226,.081-.4Z"
          fill="#fbec5a"></path>
    <path id="1705926025362-471600_C-28"
          d="M13.789,10.616c.172-.118,.165-.27,.078-.401-.116-.174-.908-.842-.908-.842,0,0,.314,.987,.43,1.161,.087,.13,.226,.198,.4,.081Z"
          fill="#fbec5a"></path>
    <path id="1705926025362-471600_C-29"
          d="M19.927,13.159c.043,.204,.187,.256,.341,.225,.205-.041,1.125-.517,1.125-.517,0,0-1.032-.088-1.237-.047-.154,.031-.269,.133-.228,.338Z"
          fill="#fbec5a"></path>
    <path id="1705926025362-471600_C-30"
          d="M16.788,17.856c-.204,.043-.256,.187-.225,.341,.041,.205,.517,1.125,.517,1.125,0,0,.088-1.032,.047-1.237-.031-.154-.133-.269-.338-.228Z"
          fill="#fbec5a"></path>
    <path id="1705926025362-471600_C-31"
          d="M12.09,14.717c-.043-.204-.187-.256-.341-.225-.205,.041-1.125,.517-1.125,.517,0,0,1.032,.088,1.237,.047,.154-.031,.269-.133,.228-.338Z"
          fill="#fbec5a"></path>
    <path id="1705926025362-471600_C-32"
          d="M15.229,10.02c.204-.043,.256-.187,.225-.341-.041-.205-.517-1.125-.517-1.125,0,0-.088,1.032-.047,1.237,.031,.154,.133,.269,.338,.228Z"
          fill="#fbec5a"></path>
    <path
        d="M15.381,21.553c-.262,.013-.507,.122-.768,.141,.066,.178-.157,.336-.296,.202-.098,.031-.199,.01-.283-.047-.326,.126-.438,.672-.027,.357,.107-.068-.045,.155,.03,.106,.824-.278,1.811-.053,2.646-.147-.529-.098-1.111-.035-1.617-.133,.061-.084,.134-.159,.201-.237-.054-.009-.106-.02-.16-.033,.095-.065,.183-.139,.275-.209h0Z"
        fill="#fbec5a"></path>
    <path
        d="M19.45,22.456c-.189,.114-.334-.088-.492-.148-.118,.163-.393,.721-.499,.182-.151,.127-.227,.416-.472,.377-.259-.468-.364,.332-.654,.034-.031-.147-.104-.32-.184-.086-.055,.165-.231,.287-.402,.214-.093-.076,.075-.359-.044-.339-.178,.117-.281,.409-.529,.386-.096-.108,.058-.291-.047-.411-.125,.16-.263,.394-.479,.411-.104-.084,.004-.327-.062-.362-.221,.133-.455,.369-.733,.295,.506-1.083,2.024-.344,2.918-.762,.102-.062,.349-.15,.261-.294-.078-.114,.203-.069,.23-.202,.454-.447,1.524,.043,2.172-.03,.048,.309-.295,.527-.572,.431-.132,.021-.33-.303-.291-.067-.027,.124-.008,.288-.121,.371m-5.419-.634c.903,.018,1.617-1.001,2.425-1.321,.631-.191,1.302-.168,1.936-.344,.482-.66,1.302-.896,1.988-1.276,.543-.535,1.009-1.606,1.968-1.467-.016,.269-.171,.508-.357,.694,.153-.003,.284-.1,.438-.094,.053,.276-.246,.421-.404,.593,.712,.068-.308,.378-.479,.6,.484,.095-.338,.196-.323,.398,.256,.242-.684,.209-.273,.448-.082,.124-.258,.15-.401,.138,.141,.615-.997,.841-1.476,.944,.511,.124,2.837-.423,1.76-.831,.297-.183,.671-.118,.99-.227,.008-.103-.243-.075-.336-.098-.494-.098,.712-.163,.815-.36-.186-.086-.421-.01-.62-.081,.246-.068,1.587-.465,.77-.486-.187,.033-.273-.057-.061-.134,.722-.438,2.066-1.473,.262-.802,1.482-1.119,2.217-2.57,.007-.62,1.379-1.223,1.922-3.365-.02-.741,1.057-1.83,.701-3.668-.249-.492,.117-.273,.256-1.744-.197-1.117-.529,2.79-3.132,4.783-5.896,4.945-.563,.859-1.624,1.121-2.265,1.732"
        fill="#fbec5a"></path>
    <path
        d="M15.886,20.076c-.091,.166-.145,.357-.263,.505-.662-.588-1.712-.067-2.375-.651-1.256-.909-2.379-1.756-3.421-2.935-.105,.026-.057,.252-.186,.136-.07-.05-.244-.054-.26,.015,.158,.208,.316,.445,.338,.711-.108,.011-.299-.023-.352,.017,.143,.214,.326,.404,.534,.556-.1,.016-.297,.007-.335,.048,.207,.168,.428,.341,.699,.384,.055,.073-.081,.214,.034,.276,.066,.027,.286,.073,.135,.148-.252,.26,.305,.231,.354,.357-.475,.25,.258,.394,.495,.412-.105,.089-.235,.139-.344,.222,.683,.379,1.438,.647,2.197,.829-.98-.036-1.846-.517-2.743-.856,.135-.07,.291-.087,.431-.148-.291-.058-.608-.077-.864-.237,.039-.07,.188-.073,.27-.109,.474-.133-.527-.11-.633-.261,.11-.124,.458-.045,.6-.189-.79-.158-1.694-.405-.236-.398-.761-.21-2.478-1.49-.648-.776-1.235-.994-2.378-2.729-.04-.667-1.008-.899-2.122-3.554,.088-.708-1.053-1.834-.715-3.71,.249-.492-.471-3.981,.959,4.279,6.275,3.808"
        fill="#fbec5a"></path>
    <path d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
          fill="#fff" opacity=".2"></path>
</svg>

export default KzFlag;
